import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src160420232/src/www.startresponse.com/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ArticleCard>`}</inlineCode>{` component should generally be used inside of a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` and
`}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` with a `}<inlineCode parentName="p">{`noGutterMdLeft`}</inlineCode>{` prop on the `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` to allow it to “hang”
to the left.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row mdxType="Row">
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Title" author="Josh Black" date="April 29, 2019" readTime="Read time: 5 min" href="/" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz12Ta0wNYBjH3+PWchsam5kRGzKXiqY66aRTzWUNy6KGQjPltoZii1SLdHbS3DbDXNIhDkbJZTEbJqebYnQ9RaaQ+211Lj9P8oUPz5f3fZ7/c/s9Kq0S9r2AqDOfULoqNCGVqKAHrNa+xjD7F1lBTnJ0TjJ0XbjrG1HBj+mlu49GrHdoOcqvgtCcdgx1kFruRKVXOv8ILsv7jPItQXleQE0zE+1Zzc7Jtez0tpKm+8kYfa0EF6KmFqCmXBYfMS/xnX79X8G0v4JRpz/KZxH9o2rwyPnEokw76zY5We/bwohZZSjtA5RPMW6bm/E69Z0pBz4zdG09yuMqodmt/1VYKxWavjJgTTMRd2GvFYwJsCkevHK/4bKygcGxTYzO6CDG/IuU7W/Z3wixZTB4QyshBzsw1HcLOlBZz2B3hQOvg2/xN3dxTMSK7rQR72PGdPY7a2vAPbOZUTueE/kI8u92st33ISePP+NcC8y74WBS7juSHnaSWQ1q270f6HdJSx75hF3pxGqD5Lg9jOyjpaUBsiTBoCW30WgLiC918qULAr0jiPROpOMnRN8TEf9CpsbeYo8Up1aZ3jBxeTFqkonpJ75hLIUjUR9InFjK4UPtxFjAZe4N1MzzhJfA0TudpPhZKCz5yPGXEHxJ2vS5xrCgfLqJUXEF7XiskAD3k7jFWok0OElaAhsD6oiIq2dsxgfZplk2m8fwLa9ZWmQjSRKnPEXm7WRIQqt0d5FhwSbSq0Qw9bGd6FPNDA+7SYLfF1IXO1i11Y5n8jsGxjcJd0+kuksobxMqsALXmAbGG94zNrUNl6Uv/rDbT3eTBfuqe1rOlG2lW2zsiHiFUevAGGAj18/OmEBxDihFoxfBGRcFqTyUXqCfLfP2twhGZWjmVAibFvSGN+xv6l6uYJNsbGP9/HISJ9SQPUcQ0tnIDrIzLrgOFVJO3wWNuC5swDW8jr7hVnnruaZu6xVWJcdgIUzAzq7t4fA37CE/C9xcSc4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create" author="Josh Black" href="https://www.ibm.com" actionIcon="arrowRight" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz12Ta0wNYBjH3+PWchsam5kRGzKXiqY66aRTzWUNy6KGQjPltoZii1SLdHbS3DbDXNIhDkbJZTEbJqebYnQ9RaaQ+211Lj9P8oUPz5f3fZ7/c/s9Kq0S9r2AqDOfULoqNCGVqKAHrNa+xjD7F1lBTnJ0TjJ0XbjrG1HBj+mlu49GrHdoOcqvgtCcdgx1kFruRKVXOv8ILsv7jPItQXleQE0zE+1Zzc7Jtez0tpKm+8kYfa0EF6KmFqCmXBYfMS/xnX79X8G0v4JRpz/KZxH9o2rwyPnEokw76zY5We/bwohZZSjtA5RPMW6bm/E69Z0pBz4zdG09yuMqodmt/1VYKxWavjJgTTMRd2GvFYwJsCkevHK/4bKygcGxTYzO6CDG/IuU7W/Z3wixZTB4QyshBzsw1HcLOlBZz2B3hQOvg2/xN3dxTMSK7rQR72PGdPY7a2vAPbOZUTueE/kI8u92st33ISePP+NcC8y74WBS7juSHnaSWQ1q270f6HdJSx75hF3pxGqD5Lg9jOyjpaUBsiTBoCW30WgLiC918qULAr0jiPROpOMnRN8TEf9CpsbeYo8Up1aZ3jBxeTFqkonpJ75hLIUjUR9InFjK4UPtxFjAZe4N1MzzhJfA0TudpPhZKCz5yPGXEHxJ2vS5xrCgfLqJUXEF7XiskAD3k7jFWok0OElaAhsD6oiIq2dsxgfZplk2m8fwLa9ZWmQjSRKnPEXm7WRIQqt0d5FhwSbSq0Qw9bGd6FPNDA+7SYLfF1IXO1i11Y5n8jsGxjcJd0+kuksobxMqsALXmAbGG94zNrUNl6Uv/rDbT3eTBfuqe1rOlG2lW2zsiHiFUevAGGAj18/OmEBxDihFoxfBGRcFqTyUXqCfLfP2twhGZWjmVAibFvSGN+xv6l6uYJNsbGP9/HISJ9SQPUcQ0tnIDrIzLrgOFVJO3wWNuC5swDW8jr7hVnnruaZu6xVWJcdgIUzAzq7t4fA37CE/C9xcSc4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create a longer title example in this space" href="https://www.ibm.com" disabled mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz12Ta0wNYBjH3+PWchsam5kRGzKXiqY66aRTzWUNy6KGQjPltoZii1SLdHbS3DbDXNIhDkbJZTEbJqebYnQ9RaaQ+211Lj9P8oUPz5f3fZ7/c/s9Kq0S9r2AqDOfULoqNCGVqKAHrNa+xjD7F1lBTnJ0TjJ0XbjrG1HBj+mlu49GrHdoOcqvgtCcdgx1kFruRKVXOv8ILsv7jPItQXleQE0zE+1Zzc7Jtez0tpKm+8kYfa0EF6KmFqCmXBYfMS/xnX79X8G0v4JRpz/KZxH9o2rwyPnEokw76zY5We/bwohZZSjtA5RPMW6bm/E69Z0pBz4zdG09yuMqodmt/1VYKxWavjJgTTMRd2GvFYwJsCkevHK/4bKygcGxTYzO6CDG/IuU7W/Z3wixZTB4QyshBzsw1HcLOlBZz2B3hQOvg2/xN3dxTMSK7rQR72PGdPY7a2vAPbOZUTueE/kI8u92st33ISePP+NcC8y74WBS7juSHnaSWQ1q270f6HdJSx75hF3pxGqD5Lg9jOyjpaUBsiTBoCW30WgLiC918qULAr0jiPROpOMnRN8TEf9CpsbeYo8Up1aZ3jBxeTFqkonpJ75hLIUjUR9InFjK4UPtxFjAZe4N1MzzhJfA0TudpPhZKCz5yPGXEHxJ2vS5xrCgfLqJUXEF7XiskAD3k7jFWok0OElaAhsD6oiIq2dsxgfZplk2m8fwLa9ZWmQjSRKnPEXm7WRIQqt0d5FhwSbSq0Qw9bGd6FPNDA+7SYLfF1IXO1i11Y5n8jsGxjcJd0+kuksobxMqsALXmAbGG94zNrUNl6Uv/rDbT3eTBfuqe1rOlG2lW2zsiHiFUevAGGAj18/OmEBxDihFoxfBGRcFqTyUXqCfLfP2twhGZWjmVAibFvSGN+xv6l6uYJNsbGP9/HISJ9SQPUcQ0tnIDrIzLrgOFVJO3wWNuC5swDW8jr7hVnnruaZu6xVWJcdgIUzAzq7t4fA37CE/C9xcSc4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard title="Explore & Create" color="dark" href="https://www.ibm.com" actionIcon="download" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz12Ta0wNYBjH3+PWchsam5kRGzKXiqY66aRTzWUNy6KGQjPltoZii1SLdHbS3DbDXNIhDkbJZTEbJqebYnQ9RaaQ+211Lj9P8oUPz5f3fZ7/c/s9Kq0S9r2AqDOfULoqNCGVqKAHrNa+xjD7F1lBTnJ0TjJ0XbjrG1HBj+mlu49GrHdoOcqvgtCcdgx1kFruRKVXOv8ILsv7jPItQXleQE0zE+1Zzc7Jtez0tpKm+8kYfa0EF6KmFqCmXBYfMS/xnX79X8G0v4JRpz/KZxH9o2rwyPnEokw76zY5We/bwohZZSjtA5RPMW6bm/E69Z0pBz4zdG09yuMqodmt/1VYKxWavjJgTTMRd2GvFYwJsCkevHK/4bKygcGxTYzO6CDG/IuU7W/Z3wixZTB4QyshBzsw1HcLOlBZz2B3hQOvg2/xN3dxTMSK7rQR72PGdPY7a2vAPbOZUTueE/kI8u92st33ISePP+NcC8y74WBS7juSHnaSWQ1q270f6HdJSx75hF3pxGqD5Lg9jOyjpaUBsiTBoCW30WgLiC918qULAr0jiPROpOMnRN8TEf9CpsbeYo8Up1aZ3jBxeTFqkonpJ75hLIUjUR9InFjK4UPtxFjAZe4N1MzzhJfA0TudpPhZKCz5yPGXEHxJ2vS5xrCgfLqJUXEF7XiskAD3k7jFWok0OElaAhsD6oiIq2dsxgfZplk2m8fwLa9ZWmQjSRKnPEXm7WRIQqt0d5FhwSbSq0Qw9bGd6FPNDA+7SYLfF1IXO1i11Y5n8jsGxjcJd0+kuksobxMqsALXmAbGG94zNrUNl6Uv/rDbT3eTBfuqe1rOlG2lW2zsiHiFUevAGGAj18/OmEBxDihFoxfBGRcFqTyUXqCfLfP2twhGZWjmVAibFvSGN+xv6l6uYJNsbGP9/HISJ9SQPUcQ0tnIDrIzLrgOFVJO3wWNuC5swDW8jr7hVnnruaZu6xVWJcdgIUzAzq7t4fA37CE/C9xcSc4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Explore & Create" author="Josh Black" readTime="Read time: 5 min" color="dark" href="https://www.ibm.com" actionIcon="email" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz12Ta0wNYBjH3+PWchsam5kRGzKXiqY66aRTzWUNy6KGQjPltoZii1SLdHbS3DbDXNIhDkbJZTEbJqebYnQ9RaaQ+211Lj9P8oUPz5f3fZ7/c/s9Kq0S9r2AqDOfULoqNCGVqKAHrNa+xjD7F1lBTnJ0TjJ0XbjrG1HBj+mlu49GrHdoOcqvgtCcdgx1kFruRKVXOv8ILsv7jPItQXleQE0zE+1Zzc7Jtez0tpKm+8kYfa0EF6KmFqCmXBYfMS/xnX79X8G0v4JRpz/KZxH9o2rwyPnEokw76zY5We/bwohZZSjtA5RPMW6bm/E69Z0pBz4zdG09yuMqodmt/1VYKxWavjJgTTMRd2GvFYwJsCkevHK/4bKygcGxTYzO6CDG/IuU7W/Z3wixZTB4QyshBzsw1HcLOlBZz2B3hQOvg2/xN3dxTMSK7rQR72PGdPY7a2vAPbOZUTueE/kI8u92st33ISePP+NcC8y74WBS7juSHnaSWQ1q270f6HdJSx75hF3pxGqD5Lg9jOyjpaUBsiTBoCW30WgLiC918qULAr0jiPROpOMnRN8TEf9CpsbeYo8Up1aZ3jBxeTFqkonpJ75hLIUjUR9InFjK4UPtxFjAZe4N1MzzhJfA0TudpPhZKCz5yPGXEHxJ2vS5xrCgfLqJUXEF7XiskAD3k7jFWok0OElaAhsD6oiIq2dsxgfZplk2m8fwLa9ZWmQjSRKnPEXm7WRIQqt0d5FhwSbSq0Qw9bGd6FPNDA+7SYLfF1IXO1i11Y5n8jsGxjcJd0+kuksobxMqsALXmAbGG94zNrUNl6Uv/rDbT3eTBfuqe1rOlG2lW2zsiHiFUevAGGAj18/OmEBxDihFoxfBGRcFqTyUXqCfLfP2twhGZWjmVAibFvSGN+xv6l6uYJNsbGP9/HISJ9SQPUcQ0tnIDrIzLrgOFVJO3wWNuC5swDW8jr7hVnnruaZu6xVWJcdgIUzAzq7t4fA37CE/C9xcSc4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterMdLeft mdxType="Column">
    <ArticleCard subTitle="subTitle" title="Explore & Create" author="Josh Black" date="April 29, 2019" readTime="Read time: 5 min" color="dark" disabled mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6klEQVQoz12Ta0wNYBjH3+PWchsam5kRGzKXiqY66aRTzWUNy6KGQjPltoZii1SLdHbS3DbDXNIhDkbJZTEbJqebYnQ9RaaQ+211Lj9P8oUPz5f3fZ7/c/s9Kq0S9r2AqDOfULoqNCGVqKAHrNa+xjD7F1lBTnJ0TjJ0XbjrG1HBj+mlu49GrHdoOcqvgtCcdgx1kFruRKVXOv8ILsv7jPItQXleQE0zE+1Zzc7Jtez0tpKm+8kYfa0EF6KmFqCmXBYfMS/xnX79X8G0v4JRpz/KZxH9o2rwyPnEokw76zY5We/bwohZZSjtA5RPMW6bm/E69Z0pBz4zdG09yuMqodmt/1VYKxWavjJgTTMRd2GvFYwJsCkevHK/4bKygcGxTYzO6CDG/IuU7W/Z3wixZTB4QyshBzsw1HcLOlBZz2B3hQOvg2/xN3dxTMSK7rQR72PGdPY7a2vAPbOZUTueE/kI8u92st33ISePP+NcC8y74WBS7juSHnaSWQ1q270f6HdJSx75hF3pxGqD5Lg9jOyjpaUBsiTBoCW30WgLiC918qULAr0jiPROpOMnRN8TEf9CpsbeYo8Up1aZ3jBxeTFqkonpJ75hLIUjUR9InFjK4UPtxFjAZe4N1MzzhJfA0TudpPhZKCz5yPGXEHxJ2vS5xrCgfLqJUXEF7XiskAD3k7jFWok0OElaAhsD6oiIq2dsxgfZplk2m8fwLa9ZWmQjSRKnPEXm7WRIQqt0d5FhwSbSq0Qw9bGd6FPNDA+7SYLfF1IXO1i11Y5n8jsGxjcJd0+kuksobxMqsALXmAbGG94zNrUNl6Uv/rDbT3eTBfuqe1rOlG2lW2zsiHiFUevAGGAj18/OmEBxDihFoxfBGRcFqTyUXqCfLfP2twhGZWjmVAibFvSGN+xv6l6uYJNsbGP9/HISJ9SQPUcQ0tnIDrIzLrgOFVJO3wWNuC5swDW8jr7hVnnruaZu6xVWJcdgIUzAzq7t4fA37CE/C9xcSc4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Dark article layout mockup",
              "title": "Dark article layout mockup",
              "src": "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png",
              "srcSet": ["/static/ebd956040be4c438af81a5204152b45c/7fc1e/Article_06.png 288w", "/static/ebd956040be4c438af81a5204152b45c/a5df1/Article_06.png 576w", "/static/ebd956040be4c438af81a5204152b45c/3cbba/Article_06.png 1152w", "/static/ebd956040be4c438af81a5204152b45c/0b124/Article_06.png 1728w", "/static/ebd956040be4c438af81a5204152b45c/0c3f5/Article_06.png 1856w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ArticleCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ArticleCard/ArticleCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ArticleCard",
        "path": "components/ArticleCard/ArticleCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ArticleCard"
      }}>{`<Row>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      subTitle="subTitle"
      title="Title"
      author="Josh Black"
      date="April 29, 2019"
      readTime="Read time: 5 min"
      href="/">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      href="https://www.ibm.com"
      actionIcon="arrowRight">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create a longer title example in this space"
      href="https://www.ibm.com"
      disabled>
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      color="dark"
      href="https://www.ibm.com"
      actionIcon="download">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      readTime="Read time: 5 min"
      color="dark"
      href="https://www.ibm.com"
      actionIcon="email">
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterMdLeft>
    <ArticleCard
      title="Explore & Create"
      author="Josh Black"
      date="April 29, 2019"
      readTime="Read time: 5 min"
      color="dark"
      disabled>
      
![Dark article layout mockup](/images/Article_06.png)


    </ArticleCard>

  </Column>
</Row>

`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Card smaller sub title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`author`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Author of article`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date article published`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`readTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Read time of article`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`Launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`ArrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`Download`}</inlineCode>{`, `}<inlineCode parentName="td">{`Disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`Email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      